
import { IonContent, IonFooter, IonButton, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import PageTitle from '@/components/layout/PageTitle.vue';
import { TokenService } from '@/services';
import { REMOVE_CURRENT_USER } from '@/store/actions.type';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'AccountPage',
  components: {
    IonPage,
    IonContent,
    IonFooter,
    IonButton,
    PageTitle
  },
  setup() {
    const router = useRouter();
    const tokenService = new TokenService();
    const store = useStore();

    const logout = () => {
      tokenService.removeToken();
      store.dispatch(REMOVE_CURRENT_USER);
      router.replace('/login');
    };

    return {
      router,
      logout
    };
  }
});
